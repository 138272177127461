@import './fonts.css';
$primary-color: #005FAA;
$navbar-background: #000000; // Svetlija nijansa plave za pozadinu navigacionog bara
$nav-link-color: #ffffff; // Bela boja za linkove
$nav-link-active-color: #d9d9d9; // Tamnija nijansa bele za aktivne linkove

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
}

header, .mechanism, .prizes, .winners, .rules {
  padding: 60px 0;
}


.App {
  text-align: center;
  background: url(../public/rsz_12background.png) no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  background-position: center center;
  // min-width: 100vw;
  // padding-top: 50px;
  // @media screen and (min-width: 991px) {
  //   background: url(../../public/desktopMehanikaPozadina.png) no-repeat center center fixed;
  // }
  // @media screen and (max-width: 991px) {
  //   background: url(../../public/homePagePozadina.png) no-repeat center center fixed;
  //   background-size: cover;
  //   background-position: left top, right bottom;
  // }
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;
  // background-position: left top, right bottom;

  // @media (max-width: 1750px) and (min-width: 1561px) {
  //   background: url(../public/rsz_12background.png) no-repeat;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   min-height: 100vh;
  //   background-position: center center;
  // }
  // @media (max-width: 1560px) and (min-width: 1400px){
  //   background: url(../public/rsz_2background.png) no-repeat;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   min-height: 100vh;
  //   background-position: center center;
  // }
  @media (max-width: 1399px) and (min-width: 1200px){
    background: url(../public/rsz_1background.png) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-position: center center;
  }
  @media (max-width: 1200px) and (min-width: 992px){
    background: url(../public/rsz_1200_background.png) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-position: center center;
  }
  @media (max-width: 991px) and (min-width: 768px){
    background: url(../public/mobile_background.png) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-position: center center;
  }
  @media (max-width: 767px) and (min-width: 576px){
    background: url(../public/mobile_767_background.png) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-position: center center;
  }

  @media (max-width: 575px) {
    background: url(../public/mobile2_background.png) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-position: center center;
  }
    @media (max-width: 1570px) and (min-width: 1400px) {
    background: url(../public/rsz_1570_background.png) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-position: center center;
  }
  
  // @media (max-width: 1520px) and (min-width: 1471px) {
  //   font-size: 7.2rem;
  // }
  
  // @media (max-width: 1470px) and (min-width: 1421px) {
  //   font-size: 7.2rem;
  // }
  
  // @media (max-width: 1420px) and (min-width: 1400px) {
  //   font-size: 7.2rem;
  // }
}

.bg-primary {
  background-color: $primary-color;
}


.line-background-section {
  background-image: url(../public/Layer_1.png); // Putanja do vaše slike linija
  background-size: cover; // Ili contain, zavisno od efekta koji želite
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  height: 70px; // Podesite visinu sekcije prema potrebi
}

@media (min-width: 1921px) {
  .line-background-section {
    height: 100px; // Podesite visinu sekcije prema potrebi
  }
}

@media (max-width: 992px) {
  .line-background-section {
    height: 40px; // Podesite visinu sekcije prema potrebi
  }
}


.mechanism-section {
  // background-color: $primary-color !important;

  .mt5{
    @media (min-width: 1400px) {
      margin-top: 3rem; 
    }

    @media (max-width: 1399px) and (min-width: 992px) {
      margin-top: 4rem; 
    }
  
    @media (max-width: 992px) {
      margin-top: 0; 
    }
  }

  h2{
    line-height: 0.8 !important;
  }


  .font-title{
    text-transform: uppercase;
    font-family: "AkiraExpandedDemo";
    font-weight: 900;
    color: white;
    text-align: center;
    font-size: 10rem;
    // @media (max-width: 1750px) and (min-width: 1561px) {
    //   font-size: 9rem;
    // }
    // @media (max-width: 1560px) and (min-width: 1401px){
    //   font-size: 8rem;
    // }
    // @media (max-width: 1400px) and (min-width: 1201px){
    //   font-size: 7rem;
    // }
    // @media (max-width: 1200px) and (min-width: 992px){
    //   font-size: 6rem;
    // }
    @media (max-width: 1770px) and (min-width: 1721px) {
      font-size: 9rem;
    }
    
    @media (max-width: 1720px) and (min-width: 1671px) {
      font-size: 9rem;
    }
    
    @media (max-width: 1670px) and (min-width: 1621px) {
      font-size: 9rem;
    }
    
    @media (max-width: 1620px) and (min-width: 1571px) {
      font-size: 8rem;
    }
    
    @media (max-width: 1570px) and (min-width: 1521px) {
      font-size: 8rem;
    }
    
    @media (max-width: 1520px) and (min-width: 1471px) {
      font-size: 8rem;
    }
    
    @media (max-width: 1470px) and (min-width: 1421px) {
      font-size: 8rem;
    }
    
    @media (max-width: 1420px) and (min-width: 1400px) {
      font-size: 8rem;
    }
    @media (max-width: 1399px) and (min-width: 1350px) {
      font-size: 6.4rem;
    }
    
    @media (max-width: 1349px) and (min-width: 1300px) {
      font-size: 6.4rem;
    }
    
    @media (max-width: 1299px) and (min-width: 1250px) {
      font-size: 6.4rem;
    }
    
    @media (max-width: 1249px) and (min-width: 1200px) {
      font-size: 6.4rem;
    }

    @media (max-width: 1199px) and (min-width: 1150px) {
      font-size: 5.4rem;
    }
    
    @media (max-width: 1149px) and (min-width: 1100px) {
      font-size: 5.4rem;
    }
    
    @media (max-width: 1099px) and (min-width: 1050px) {
      font-size: 5.2rem;
    }
    
    @media (max-width: 1049px) and (min-width: 992px) {
      font-size: 5rem;
    }
    @media (max-width: 991px) and (min-width: 941px){
      font-size: 4rem;
    }
  
    @media (max-width: 940px) and (min-width: 890px){  
      font-size: 4rem;
    }
  
    @media (max-width: 889px) and (min-width: 839px){  
      font-size: 4rem;
    }
    
    @media (max-width: 838px) and (min-width: 768px){
      font-size: 4rem;
    }
    
    .ultra-text {
      color: orange; /* Postavite narandžastu boju */
      font-family: "DINCondensedBold"; /* Ili drugi font koji želite */
      font-weight: 600; /* Ako želite podebljani tekst */
      font-size: 9.2rem;
      // @media (max-width: 1750px) and (min-width: 1561px) {
      //   font-size: 8.2rem;
      // }
      // @media (max-width: 1560px) and (min-width: 1401px){
      //   font-size: 7.2rem;
      // }
      // @media (max-width: 1400px) and (min-width: 1201px){
      //   font-size: 6.4rem;
      // }
      // @media (max-width: 1200px) and (min-width: 992px){
      //   font-size: 5.5rem;
      // }
      @media (max-width: 1770px) and (min-width: 1721px) {
        font-size: 8.2rem;
      }
      
      @media (max-width: 1720px) and (min-width: 1671px) {
        font-size: 8.2rem;
      }
      
      @media (max-width: 1670px) and (min-width: 1621px) {
        font-size: 8.2rem;
      }

      @media (max-width: 1620px) and (min-width: 1571px) {
        font-size: 7.2rem;
      }
      
      @media (max-width: 1570px) and (min-width: 1521px) {
        font-size: 7.2rem;
      }
      
      @media (max-width: 1520px) and (min-width: 1471px) {
        font-size: 7.2rem;
      }
      
      @media (max-width: 1470px) and (min-width: 1421px) {
        font-size: 7.2rem;
      }
      
      @media (max-width: 1420px) and (min-width: 1400px) {
        font-size: 7.2rem;
      }
      @media (max-width: 1399px) and (min-width: 1350px) {
        font-size: 5.8rem;
      }
      
      @media (max-width: 1349px) and (min-width: 1300px) {
        font-size: 5.8rem;
      }
      
      @media (max-width: 1299px) and (min-width: 1250px) {
        font-size: 5.8rem;
      }
      
      @media (max-width: 1249px) and (min-width: 1200px) {
        font-size: 5.8rem;
      }
      @media (max-width: 1199px) and (min-width: 1150px) {
        font-size: 5rem;
      }
      
      @media (max-width: 1149px) and (min-width: 1100px) {
        font-size: 4.9rem;
      }
      
      @media (max-width: 1099px) and (min-width: 1050px) {
        font-size: 4.8rem;
      }
      
      @media (max-width: 1049px) and (min-width: 992px) {
        font-size: 4.6rem;
      }      
      @media (max-width: 991px) and (min-width: 941px){
        font-size: 3.8rem;
      }
    
      @media (max-width: 940px) and (min-width: 890px){  
        font-size: 3.8rem;
      }
    
      @media (max-width: 889px) and (min-width: 839px){  
        font-size: 3.8rem;
      }
      
      @media (max-width: 838px) and (min-width: 768px){
        font-size: 3.8rem;
      }
    }
  }

  .font-dnvene-glavne{
    text-transform: uppercase;
    font-family: "AkiraExpandedDemo";
    // font-weight: bold;
    font-weight: 900;
    color: white;
    text-align: center;
    font-size: 4rem;
    // @media (max-width: 1750px) and (min-width: 1561px) {
    //   font-size: 3rem;
    // }
    // @media (max-width: 1560px) and (min-width: 1401px){
    //   font-size: 2.6rem;
    // }
    // @media (max-width: 1400px) and (min-width: 1201px){
    //   font-size: 2.2rem;
    // }
    // @media (max-width: 1200px) and (min-width: 993px){
    //   font-size: 2rem;
    // }
    @media (max-width: 1620px) and (min-width: 1571px) {
      font-size: 2.6rem;
    }
    
    @media (max-width: 1570px) and (min-width: 1521px) {
      font-size: 2.6rem;
    }
    
    @media (max-width: 1520px) and (min-width: 1471px) {
      font-size: 2.6rem;
    }
    
    @media (max-width: 1470px) and (min-width: 1421px) {
      font-size: 2.6rem;
    }
    
    @media (max-width: 1420px) and (min-width: 1400px) {
      font-size: 2.6rem;
    }
    @media (max-width: 1399px) and (min-width: 1350px) {
      font-size: 2rem;
    }
    
    @media (max-width: 1349px) and (min-width: 1300px) {
      font-size: 2rem;
    }
    
    @media (max-width: 1299px) and (min-width: 1250px) {
      font-size: 2rem;
    }
    
    @media (max-width: 1249px) and (min-width: 1200px) {
      font-size: 2rem;
    }
    @media (max-width: 1199px) and (min-width: 1150px) {
      font-size: 1.8rem;
    }
    
    @media (max-width: 1149px) and (min-width: 1100px) {
      font-size: 1.8rem;
    }
    
    @media (max-width: 1099px) and (min-width: 1050px) {
      font-size: 1.8rem;
    }
    
    @media (max-width: 1049px) and (min-width: 992px) {
      font-size: 1.8rem;
    } 

    @media (max-width: 991px) and (min-width: 941px){
      font-size: 1.8rem;
    }
  
    @media (max-width: 940px) and (min-width: 890px){  
      font-size: 1.8rem;
    }
  
    @media (max-width: 889px) and (min-width: 839px){  
      font-size: 1.8rem;
    }
    
    @media (max-width: 838px) and (min-width: 768px){
      font-size: 1.8rem;
    }
    @media (max-width: 767px) and (min-width: 717px){
      font-size: 1.8rem;
    } 
    
	@media (max-width: 716px) and (min-width: 666px){
    font-size: 1.8rem;
    } 	
	
	@media (max-width: 665px) and (min-width: 615px){
    font-size: 1.8rem;
    } 

	@media (max-width: 665px) and (min-width: 615px){
    font-size: 1.8rem;
    } 	
	
	@media (max-width: 614px) and (min-width: 576px){
    font-size: 1.8rem;
    }
  @media (max-width: 575px) {
    font-size: 1.8rem;
  }  
    
    .ultra-text2 {
      color: orange; /* Postavite narandžastu boju */
      font-family: "DINCondensedBold"; /* Ili drugi font koji želite */
      font-weight: 600; /* Ako želite podebljani tekst */
      font-size: 3rem;
      // @media (max-width: 1750px) and (min-width: 1561px) {
      //   font-size: 2.4rem;
      // }
      // @media (max-width: 1560px) and (min-width: 1401px){
      //   font-size: 2.2rem;
      // }
      // @media (max-width: 1400px) and (min-width: 1201px){
      //   font-size: 2rem;
      // }
      // @media (max-width: 1200px) and (min-width: 993px){
      //   font-size: 1.8rem;
      // }
      @media (max-width: 1620px) and (min-width: 1571px) {
        font-size: 2.4rem;
      }
      
      @media (max-width: 1570px) and (min-width: 1521px) {
        font-size: 2.4rem;
      }
      
      @media (max-width: 1520px) and (min-width: 1471px) {
        font-size: 2.4rem;
      }
      
      @media (max-width: 1470px) and (min-width: 1421px) {
        font-size: 2.4rem;
      }
      
      @media (max-width: 1420px) and (min-width: 1400px) {
        font-size: 2.4rem;
      }
      @media (max-width: 1399px) and (min-width: 1350px) {
        font-size: 1.8rem;
      }
      
      @media (max-width: 1349px) and (min-width: 1300px) {
        font-size: 1.8rem;
      }
      
      @media (max-width: 1299px) and (min-width: 1250px) {
        font-size: 1.8rem;
      }
      
      @media (max-width: 1249px) and (min-width: 1200px) {
        font-size: 1.8rem;
      }  
      @media (max-width: 1199px) and (min-width: 1150px) {
        font-size: 1.6rem;
      }
      
      @media (max-width: 1149px) and (min-width: 1100px) {
        font-size: 1.6rem;
      }
      
      @media (max-width: 1099px) and (min-width: 1050px) {
        font-size: 1.6rem;
      }
      
      @media (max-width: 1049px) and (min-width: 992px) {
        font-size: 1.6rem;
      }      
      @media (max-width: 991px) and (min-width: 941px){
        font-size: 1.8rem;
      }
    
      @media (max-width: 940px) and (min-width: 890px){  
        font-size: 1.8rem;
      }
    
      @media (max-width: 889px) and (min-width: 839px){  
        font-size: 1.8rem;
      }
      
      @media (max-width: 838px) and (min-width: 768px){
        font-size: 1.8rem;
      }
      @media (max-width: 767px) and (min-width: 717px){
        font-size: 1.8rem;
      } 
      
    @media (max-width: 716px) and (min-width: 666px){
      font-size: 1.8rem;
      } 	
    
    @media (max-width: 665px) and (min-width: 615px){
      font-size: 1.8rem;
      } 
  
    @media (max-width: 665px) and (min-width: 615px){
      font-size: 1.8rem;
      } 	
    
    @media (max-width: 614px) and (min-width: 576px){
      font-size: 1.8rem;
      }
    @media (max-width: 575px) {
      font-size: 1.8rem;
    }
    }
  }
  .font-right{

    // @media (max-width: 1750px) and (min-width: 1561px) {
    //   margin-right:12% !important;
    // }
    // @media (max-width: 1560px) and (min-width: 1401px){
    //   margin-right:16% !important;
    // }
    // @media (max-width: 1400px) and (min-width: 1201px){
    //   margin-right:22% !important;
    // }
    // @media (max-width: 1200px) and (min-width: 993px){
    //   margin-right:16% !important;
    // }
    @media (max-width: 1620px) and (min-width: 1571px) {
      margin-right:12%;
    }
    
    @media (max-width: 1570px) and (min-width: 1521px) {
      margin-right:12%;
    }
    
    @media (max-width: 1520px) and (min-width: 1471px) {
      margin-right:12%;
    }
    @media (max-width: 1470px) and (min-width: 1421px) {
      margin-right:12%;
    }
    
    @media (max-width: 1420px) and (min-width: 1400px) {
      margin-right:12%;
    }
    @media (max-width: 1399px) and (min-width: 1350px) {
      margin-right:12%;
    }
    
    @media (max-width: 1349px) and (min-width: 1300px) {
      margin-right:12%;
    }
    
    @media (max-width: 1299px) and (min-width: 1250px) {
      margin-right:12%;
    }
    
    @media (max-width: 1249px) and (min-width: 1200px) {
      margin-right:12%;
    }
    @media (max-width: 1199px) and (min-width: 1150px) {
      margin-right:12%;
    }
    
    @media (max-width: 1149px) and (min-width: 1100px) {
      margin-right:12%;
    }
    
    @media (max-width: 1099px) and (min-width: 1050px) {
      margin-right:12%;
    }
    
    @media (max-width: 1049px) and (min-width: 992px) {
      margin-right:12%;
    }        
    @media (max-width: 991px) and (min-width: 941px){
      margin-right:0%;
    }
  
    @media (max-width: 940px) and (min-width: 890px){  
      margin-right:0%;
    }
  
    @media (max-width: 889px) and (min-width: 839px){  
      margin-right:0%;
    }
    
    @media (max-width: 838px) and (min-width: 768px){
      margin-right:0%;
    }
    @media (max-width: 767px) and (min-width: 576px){
      font-size: 1.8rem;
    } 
    
    @media (max-width: 575px) {
      font-size: 1.8rem;
    }   
  }
  .font-left{
    // @media (max-width: 1750px) and (min-width: 1561px) {
    //   margin-left:12%;
    // }
    // @media (max-width: 1560px) and (min-width: 1401px){
    //   margin-left:16%;
    // }
    // @media (max-width: 1400px) and (min-width: 1201px){
    //   margin-left:22%;
    // }
    // @media (max-width: 1200px) and (min-width: 993px){
    //   margin-left:16%;
    // }
    // @media (max-width: 991px) {
    //   margin-left:16%;
    // } 
    @media (max-width: 1620px) and (min-width: 1571px) {
      margin-left:12%;
    }
    
    @media (max-width: 1570px) and (min-width: 1521px) {
      margin-left:12%;
    }
    
    @media (max-width: 1520px) and (min-width: 1471px) {
      margin-left:12%;
    }
    @media (max-width: 1470px) and (min-width: 1421px) {
      margin-left:12%;
    }
    
    @media (max-width: 1420px) and (min-width: 1400px) {
      margin-left:12%;
    }
    @media (max-width: 1399px) and (min-width: 1350px) {
      margin-left:12%;
    }
    
    @media (max-width: 1349px) and (min-width: 1300px) {
      margin-left:12%;
    }
    
    @media (max-width: 1299px) and (min-width: 1250px) {
      margin-left:12%;
    }
    
    @media (max-width: 1249px) and (min-width: 1200px) {
      margin-left:12%;
    } 
    @media (max-width: 1199px) and (min-width: 1150px) {
      margin-left:12%;
    }
    
    @media (max-width: 1149px) and (min-width: 1100px) {
      margin-left:12%;
    }
    
    @media (max-width: 1099px) and (min-width: 1050px) {
      margin-left:12%;
    }
    
    @media (max-width: 1049px) and (min-width: 992px) {
      margin-left:12%;
    }        
    @media (max-width: 991px) and (min-width: 941px){
      margin-left:0%;
    }
  
    @media (max-width: 940px) and (min-width: 890px){  
      margin-left:0%;
    }
  
    @media (max-width: 889px) and (min-width: 839px){  
      margin-left:0%;
    }
    
    @media (max-width: 838px) and (min-width: 768px){
      margin-left:0%;
    }
  }
  .img-fluid{
    max-width: 100%;
    height: auto;
    // @media (max-width: 1750px) and (min-width: 1561px) {
    //   max-width: 84%;
    // }
    // @media (max-width: 1560px) and (min-width: 1401px){
    //   max-width: 70%;
    // }
    // @media (max-width: 1400px) and (min-width: 1201px){
    //   max-width: 64%;
    // }
    // @media (max-width: 1200px) and (min-width: 992px){
    //   max-width: 78%;
    // }
    // @media (max-width: 991px) {
    //   max-width: 82%;
    // }
    @media (max-width: 1620px) and (min-width: 1571px) {
      max-width: 74%;
    }
    
    @media (max-width: 1570px) and (min-width: 1521px) {
      max-width: 74%;
    }
    
    @media (max-width: 1520px) and (min-width: 1471px) {
      max-width: 74%;
    }
    @media (max-width: 1470px) and (min-width: 1421px) {
      max-width: 74%;
    }
    
    @media (max-width: 1420px) and (min-width: 1400px) {
      max-width: 74%;
    }
    @media (max-width: 1399px) and (min-width: 1350px) {
      max-width: 74%;
    }
    
    @media (max-width: 1349px) and (min-width: 1300px) {
      max-width: 74%;
    }
    
    @media (max-width: 1299px) and (min-width: 1250px) {
      max-width: 74%;
    }
    
    @media (max-width: 1249px) and (min-width: 1200px) {
      max-width: 74%;
    } 
    @media (max-width: 1199px) and (min-width: 1150px) {
      max-width: 74%;
    }
    
    @media (max-width: 1149px) and (min-width: 1100px) {
      max-width: 74%;
    }
    
    @media (max-width: 1099px) and (min-width: 1050px) {
      max-width: 68%;
    }
    
    @media (max-width: 1049px) and (min-width: 992px) {
      max-width: 68%;
    }        
    @media (max-width: 991px) and (min-width: 941px){
      max-width: 100%;
    }
  
    @media (max-width: 940px) and (min-width: 890px){  
      max-width: 100%;
    }
  
    @media (max-width: 889px) and (min-width: 839px){  
      max-width: 100%;
    }
    
    @media (max-width: 838px) and (min-width: 768px){
      max-width: 100%;
    }
    @media (max-width: 767px) and (min-width: 717px){
      max-width: 94%;
    } 
    
    @media (max-width: 716px) and (min-width: 666px){
      max-width: 94%;
      } 	
    
    @media (max-width: 665px) and (min-width: 615px){
      max-width: 94%;
      } 

    @media (max-width: 665px) and (min-width: 615px){
      max-width: 94%;
      } 	
    
    @media (max-width: 614px) and (min-width: 576px){
      max-width: 94%;
      }
    @media (max-width: 575px) {
      max-width: 76%;
    }  

  }
  .img-fluid-r{

    // @media (max-width: 1750px) and (min-width: 1561px) {
    //   margin-right:12%;
    // }
    // @media (max-width: 1560px) and (min-width: 1401px) {
    //   margin-right:16%;
    // }
    // @media (max-width: 1400px) and (min-width: 1201px){
    //   margin-right:22%;
    // }
    // @media (max-width: 1200px) and (min-width: 992px){
    //   margin-right:16%;
    // }
    @media (max-width: 1620px) and (min-width: 1571px) {
      margin-right:12%;
    }
    
    @media (max-width: 1570px) and (min-width: 1521px) {
      margin-right:12%;
    }
    
    @media (max-width: 1520px) and (min-width: 1471px) {
      margin-right:12%;
    }
    @media (max-width: 1470px) and (min-width: 1421px) {
      margin-right:12%;
    }
    
    @media (max-width: 1420px) and (min-width: 1400px) {
      margin-right:12%;
    }
    @media (max-width: 1399px) and (min-width: 1350px) {
      margin-right:12%;
    }
    
    @media (max-width: 1349px) and (min-width: 1300px) {
      margin-right:12%;
    }
    
    @media (max-width: 1299px) and (min-width: 1250px) {
      margin-right:12%;
    }
    
    @media (max-width: 1249px) and (min-width: 1200px) {
      margin-right:12%;
    } 
    @media (max-width: 1199px) and (min-width: 1150px) {
      margin-right:12%;
    }
    
    @media (max-width: 1149px) and (min-width: 1100px) {
      margin-right:12%;
    }
    
    @media (max-width: 1099px) and (min-width: 1050px) {
      margin-right:12%;
    }
    
    @media (max-width: 1049px) and (min-width: 992px) {
      margin-right:12%;
    }        
    @media (max-width: 991px) and (min-width: 941px){
      margin-right:0%;
    }
  
    @media (max-width: 940px) and (min-width: 890px){  
      margin-right:0%;
    }
  
    @media (max-width: 889px) and (min-width: 839px){  
      margin-right:0%;
    }
    
    @media (max-width: 838px) and (min-width: 768px){
      margin-right:0%;
    }
    @media (max-width: 767px) and (min-width: 717px){
      margin-right:0%;
    } 
    
    @media (max-width: 716px) and (min-width: 666px){
      margin-right:0%;
      } 	
    
    @media (max-width: 665px) and (min-width: 615px){
      margin-right:0%;
      } 

    @media (max-width: 665px) and (min-width: 615px){
      margin-right:0%;
      } 	
    
    @media (max-width: 614px) and (min-width: 576px){
      margin-right:0%;
      }
    @media (max-width: 575px) {
      margin-right:0%;
    }   
  }
  .img-fluid-l{

    // @media (max-width: 1750px) and (min-width: 1561px) {
    //   margin-left:12%;
    // }
    // @media (max-width: 1560px) and (min-width: 1401px){
    //   margin-left:16%;
    // }
    // @media (max-width: 1400px) and (min-width: 1201px){
    //   margin-left:22%;
    // }
    // @media (max-width: 1200px) and (min-width: 992px){
    //   margin-left:16%;
    // }
    // @media (max-width: 991px) {
    //   margin-bottom: 5% !important;
    // }
    @media (max-width: 1620px) and (min-width: 1571px) {
      margin-left:12%;
    }
    
    @media (max-width: 1570px) and (min-width: 1521px) {
      margin-left:12%;
    }
    
    @media (max-width: 1520px) and (min-width: 1471px) {
      margin-left:12%;
    }
    @media (max-width: 1470px) and (min-width: 1421px) {
      margin-left:12%;
    }
    
    @media (max-width: 1420px) and (min-width: 1400px) {
      margin-left:12%;
    }
    @media (max-width: 1399px) and (min-width: 1350px) {
      margin-left:12%;
    }
    
    @media (max-width: 1349px) and (min-width: 1300px) {
      margin-left:12%;
    }
    
    @media (max-width: 1299px) and (min-width: 1250px) {
      margin-left:12%;
    }
    
    @media (max-width: 1249px) and (min-width: 1200px) {
      margin-left:12%;
    }
    @media (max-width: 1199px) and (min-width: 1150px) {
      margin-left:12%;
    }
    
    @media (max-width: 1149px) and (min-width: 1100px) {
      margin-left:12%;
    }
    
    @media (max-width: 1099px) and (min-width: 1050px) {
      margin-left:12%;
    }
    
    @media (max-width: 1049px) and (min-width: 992px) {
      margin-left:12%;
    }        
    @media (max-width: 991px) {
      margin-left:12%;
    }
    @media (max-width: 991px) and (min-width: 941px){
      margin-left:0%;
    }
  
    @media (max-width: 940px) and (min-width: 890px){  
      margin-left:0%;
    }
  
    @media (max-width: 889px) and (min-width: 839px){  
      margin-left:0%;
    }
    
    @media (max-width: 838px) and (min-width: 768px){
      margin-left:0%;
    }
    @media (max-width: 767px) and (min-width: 717px){
      margin-left:0%;
    } 
    
    @media (max-width: 716px) and (min-width: 666px){
      margin-left:0%;
      } 	
    
    @media (max-width: 665px) and (min-width: 615px){
      margin-left:0%;
      } 

    @media (max-width: 665px) and (min-width: 615px){
      margin-left:0%;
      } 	
    
    @media (max-width: 614px) and (min-width: 576px){
      margin-left:0%;
      }
    @media (max-width: 575px) {
      margin-left:0%;
    }    
  }

}

/* Stilovi za NavigationBar */
.custom-navbar {
  background-color: $navbar-background !important; // Svetlija plava boja pozadine
}


.navbar-logo {
  height: 60px; // Visina logo i grba
  max-height: 100%;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.nav-link {
  font-size: 1.2rem; // Uvećana veličina fonta
  font-weight: bold;
  margin: 0 10px; // Razmak između linkova
  color: $nav-link-color !important; // Bela boja za linkove
}

// .nav-link:hover, .nav-link:focus {
//   color: $nav-link-active-color !important; // Tamnija nijansa bele za hover i fokus
// }

.nav-link.active {
  color: $nav-link-active-color !important; // Tamnija nijansa bele za aktivne linkove
}

.ml-auto {
  margin-left: auto !important;
}
/* Sakrivanje teksta na desktop verziji */
.navbar-brand-text {
  display: block; /* Prikaži element */
  user-select: none; /* Sprečava selektovanje teksta */
}


/* Prikazivanje teksta samo na mobilnoj verziji */
@media (max-width: 768px) {
  .navbar-brand-text {
    display: flex;
    color: white;
    text-align: left;
    // font-family: 'DINCondensedBold', sans-serif;
  }

  .zgrabi-text {
    font-size: 2.6rem;
    margin: 0;
    font-weight: bold;
    font-family: 'AkiraExpandedDemo', sans-serif;
    line-height: 0.9 !important;
  }


  .ultra-nagrade {
    font-size: 2.6rem;
    margin: 0;
    font-weight: bold;
    font-family: 'AkiraExpandedDemo', sans-serif;
  }

  .ultra-text {
    color: orange;
    font-size: 2.3rem;
    font-weight: bold;
    font-family: 'DINCondensedBold', sans-serif;
  }
}


.prizes-section {
  // background-color: $primary-color !important;
  position: relative;
  padding: 60px 0;
  background-size: cover;
  background-position: center;

  .text-center-bottom{
    font-size: 0.8rem;
    margin-top: 1%;
    margin-bottom: 3%;
    font-weight: 500;
  }

  @media (max-width: 992px) {
    .text-center-bottom{
    font-size: 0.5rem !important;
    margin-bottom: -5% !important;
    // margin-bottom: 3%;
    margin-top: 0 !important;
  }
}

  .top-wave-line {
    position: absolute;
    top: 7%;
    left: 0;
    width: 50%;
    img{
      max-width: 100%;
      // max-height: auto;
    }
  }

  .bottom-wave-line {
    position: absolute;
    bottom: 4%;
    right: 0;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    img{
      max-width: 100%;
      // max-height: auto;
    }
  }

  @media (max-width: 992px) {
    .top-wave-line,
    .bottom-wave-line {
      display: none;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .prize img {
    max-width: 100%;
    height: auto;
  }

  .prize-large img {
    width: 80%; // Adjust as needed
  }

  .prize {
    text-align: center;
    color: white;
  }

  .prize-small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  @media (max-width: 768px) {
    .prizes-section .prize {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 576px) {
    .prizes-section .prize-large img {
      width: 100%; // Adjust as needed
    }
  }
}

.winners-section {
  // background-color: #005FAA;
  // background-image: url('../public/konfete.png'); // Putanja do slike konfeta
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 0;
  position: relative;
  text-align: center;
  font-family: "DINCondensedBold";

  @media (max-width: 992px) {
    padding: 20px 0; // Smanjite padding za mobilne uređaje
  }
  .col-md-10{
    width:95%;
    // @media (max-width: 1750px) and (min-width: 1561px) {
    //   width: 86%;
    // }
    // @media (max-width: 1560px) and (min-width: 1401px){
    //   max-width: 78%
    // }
    // @media (max-width: 1400px) and (min-width: 1201px){
    //   width: 72%;
    // }
    // @media (max-width: 1200px) and (min-width: 992px){
    //   max-width: 82%;
    // }
    // @media (max-width: 991px) {
    //   max-width: 82%;
    // }
    @media (max-width: 1620px) and (min-width: 1571px) {
      width:82%;
    }
    
    @media (max-width: 1570px) and (min-width: 1521px) {
      width:82%;
    }
    
    @media (max-width: 1520px) and (min-width: 1471px) {
      width:82%;
    }
    @media (max-width: 1470px) and (min-width: 1421px) {
      width:82%;
    }
    
    @media (max-width: 1420px) and (min-width: 1400px) {
      width:82%;
    }
    @media (max-width: 1399px) and (min-width: 1350px) {
      width:82%;
    }
    
    @media (max-width: 1349px) and (min-width: 1300px) {
      width:82%;
    }
    
    @media (max-width: 1299px) and (min-width: 1250px) {
      width:82%;
    }
    
    @media (max-width: 1249px) and (min-width: 1200px) {
      width:82%;
    }
    @media (max-width: 1199px) and (min-width: 1150px) {
      width:82%;
    }
    
    @media (max-width: 1149px) and (min-width: 1100px) {
      width:82%;
    }
    
    @media (max-width: 1099px) and (min-width: 1050px) {
      width:78%;
    }
    
    @media (max-width: 1049px) and (min-width: 992px) {
      width:78%;
    }        
    @media (max-width: 991px) and (min-width: 941px){
      width:82%;
    }
  
    @media (max-width: 940px) and (min-width: 890px){  
      width:82%;
    }
  
    @media (max-width: 889px) and (min-width: 839px){  
      width:82%;
    }
    
    @media (max-width: 838px) and (min-width: 768px){
      width:82%;
    }
    @media (max-width: 767px) and (min-width: 576px) {
      width:82%;
    }
    @media (max-width: 574px) and (min-width: 524px){
      width:82%;
    } 
    @media (max-width: 473px) and (min-width: 423px){
      width:88%;
    }
    @media (max-width: 422x) {
      width:92%;
    }  
  }
  .winners-header {
    position: absolute; /* Postavljanje unutar roditelja */
    top: 90%; /* Pozicija od vrha tabele */
    left: 50%; /* Centriranje po širini */
    transform: translate(-50%, 0); /* Precizno centriranje */
    display: flex; /* Raspored unutar header-a */
    justify-content: space-between; /* Ravnomeran razmak između dugmića */
    align-items: center;
    width: 90%; /* Širina cele sekcije */
    z-index: 10; /* Podiže header iznad slike */
    @media (max-width: 1400px) and (min-width: 1301px){
      top: 89%
    }
    @media (max-width: 1300px) and (min-width: 1201px){
      top: 89%
    }
    @media (max-width: 1200px) and (min-width: 992px){
      top: 89%
    }
    @media (max-width: 991px) and (min-width: 768px){
      top: 88%
    }
    @media (max-width: 767px) and (min-width: 576px){
      width: 72%;
      top: 88%
    }
    @media (max-width: 575px) {
      width: 86%;
      top: 85%;
    }
  }
  
  .awards-button,
  .week-dropdown {
    flex: 1; /* Raspored po trećinama */
    max-width: 33%; /* Maksimalna širina */
    background-color: #0d8d87; /* Boja dugmeta */
    color: white; /* Boja teksta */
    border: none; /* Bez okvira */
    padding: 10px 20px; /* Unutrašnja margina */
    font-size: 1.8rem; /* Veličina fonta */
    font-weight: bold; /* Podebljan tekst */
    text-align: center; /* Centriranje teksta */
    border-radius: 5px; /* Zaobljeni uglovi */
    cursor: pointer; /* Kursor za klik */
    transition: background-color 0.3s ease; /* Efekat prelaza za hover */
    margin: 0 5px; /* Razmak između dugmića */
    @media (max-width: 1560px) and (min-width: 1401px){
      font-size: 1.8rem;
      padding: 10px 0px 0px 0px;

    }
    @media (max-width: 1400px) and (min-width: 1301px){
      font-size: 1.6rem;
      padding: 10px 0px 0px 0px;
    }
    @media (max-width: 1300px) and (min-width: 1201px){
      font-size: 1.5rem;
      ppadding: 10px 0px 0px 0px;
    }
    @media (max-width: 1200px) and (min-width: 992px){
      font-size: 1.4rem;
      padding: 7px 0px 0px 0px;
    }
    @media (max-width: 991px) and (min-width: 576px) {
      font-size: 1rem;
      padding: 4px 0px 0px 0px;
    }
    @media (max-width: 575px) {
      font-size: 1.2rem;
      padding: 4px 0px 0px 0px;
    }
    
  }
  
  .awards-button:hover,
  .week-dropdown:hover {
    background-color: #096b63; /* Tamnija boja na hover */
  }

  .week-dropdown option {
    font-size: 1.8rem; /* Veličina fonta za opcije */
    padding: 5px; /* Unutrašnja margina */
  }
  
  .winners-table-wrapper {
    position: relative; /* Osnova za apsolutno pozicioniranje unutar tabele */
  }
  

  .winners-table-wrapper {
    position: relative;

    .table-background {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: auto; 
    }
    .no-winners-message{
      text-transform: uppercase;
      // margin-left: 20px; // Povećajte razmak između slike i teksta
      font-size: 2rem;
      // font-family: "GothamBoldPravi";
      font-weight: bold;
      color: white;
    }
    @media (max-width: 992px) {
      .no-winners-message {
        font-size: 1rem;
      }
    }

    .winners-table {
      position: absolute;
      top: 7%;
      left: 0.5%;
      width: 99%;
      height: 81%;
      overflow-y: auto;
      @media (max-width: 768px) and (min-width: 574px){
        top: 6%;
        left: 0.3%;
        width: 99%;
        height: 81.5%;
      }
      @media (max-width: 573px) and (min-width: 524px){
        top: 6%;
        left: 14%;
        width: 72%;
        height: 76.5%;
      }
      @media (max-width: 523px) and (min-width: 474px){
        top: 6%;
        left: 16.5%;
        width: 68%;
        height: 76.5%;
      }
      @media (max-width: 473px) and (min-width: 423px){
        top: 6%;
        left: 5%;
        width: 90%;
        height: 76.5%;
      }
      @media (max-width: 422px) and (min-width: 412px){
        top: 6%;
        left: 7.5%;
        width: 85%;
        height: 76.5%;
      }
      @media (max-width: 411px) and (min-width: 400px){
        top: 6%;
        left: 6.5%;
        width: 88%;
        height: 76.5%;
      }
      @media (max-width: 399px) and (min-width: 387px){
        top: 6%;
        left: 5%;
        width: 90%;
        height: 76.5%;
      }
      @media (max-width: 386px) and (min-width: 372px){
        top: 6%;
        left: 2%;
        width: 96%;
        height: 76.5%;
      }
      @media (max-width: 371px) and (min-width: 359px){
        top: 6%;
        left: 1.5%;
        width: 97%;
        height: 76.5%;
      }
      @media (max-width: 358px) {
        top: 6%;
        left: 1.5%;
        width: 98%;
        height: 76.5%;
      }

      table {
        width: 100%;
        border-collapse: collapse;

        td {
          padding: 10px;
          text-align: left;
          // border-bottom: 1px solid #ddd;
          vertical-align: middle;
        }
        @media (max-width: 992px) {
          td {
            padding: 0 !important;
          }
        }

        td:first-child {
          text-align: left; // Left align for the first column (index)
          // margin-left: -10%;
        }

        td:nth-child(2){
          display: flex;
          justify-content: center;
          text-align: center; // Center align for the second column (names)
          margin-left: 86%;
        }
        @media (max-width: 992px) {
          td:nth-child(2) {
            margin-left: 100%;
          }
        }

        td:last-child {
          // display: flex;
          // justify-content: right;
          text-align: right !important; // Center align for the second column (names)
          // margin-left: 28%;
        }

        .even {
          background-color: rgba(255, 255, 255, 0.5); // Light background for even rows
        }
      }
    }
  }
  .custom-font {
    text-transform: uppercase;
    // margin-left: 20px; // Povećajte razmak između slike i teksta
    font-size: 2rem;
    font-family: "DINCondensedBold";
    font-weight: bold;
    color: white;
  }
  @media (max-width: 992px) {
    .custom-font{
      font-size: 0.8rem;
    }
  }
}

.rules-contact-section {
  // background-color: #005FAA; // Plava pozadina
  color: white; // Bela boja teksta
  padding: 60px 0;
  text-align: center;
  font-weight: bold;
.pravila{
  @media (max-width: 992px) {
    font-size: 0.8rem !important;
    width: 68%;
    margin-left: 16% !important;
}}
  p {
    font-size: 1.2rem;
  }

  @media (max-width: 992px) {
    p{
      font-size: 1rem !important;
    }
  }
  .contact-text{
    text-transform: uppercase;
    font-size: 1.8rem;
    // font-family: "GothamBoldPravi";
    font-weight: bold;
    color: #00A19A;
    // margin-bottom: 20px;
  }
  .rules-button {
    background-color: #00A19A;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 8px;
    color: white;
    text-transform: uppercase;
    margin-top: 4px;

    &:hover {
      background-color: #00A19A;
      color: orange;
    }
  }

  @media (max-width: 992px) {
    .rules-button {
      background-color: #00A19A;
      border: none;
      padding: 5px 10px;
      font-size: 1rem;
      font-weight: bold;
      border-radius: 8px;
      color: #ffffff;
      text-transform: uppercase;
      margin-top: 4px;
  
      &:hover {
        background-color: #0088cc;
        color: white;
      }
    }
  }
  .contact-margin{
    margin-top: 6rem !important;
  }

  .contact-info {
    background-color:#00A19A; // Tamnija plava pozadina za kontakt deo
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;

    // h2 {
    //   margin-bottom: 20px;
    // }

    p {
      margin: 5px 0;
      font-size: 1.4rem;
    }
  }

  @media (max-width: 992px) {
    .contact-text{
      font-size: 1.3rem;
      margin-bottom: 10px !important;
    }
    .contact-margin{
      margin-top: 2rem !important;
    }
        p {
        margin: 5px 0;
        font-size: 1.1rem;
      }
    .contact-info {
      max-width: 300px; // Dodajte ovu liniju za smanjenje širine
      margin: 0 auto; // Centriranje elementa
  
      p {
        margin: 5px 0;
        font-size: 1.1rem;
      }
    }
  }
  .text-center-bottom{
    font-size: 0.7rem !important;
    margin-top: 10%;
    margin-bottom: -4% !important;
  }

  @media (max-width: 992px) {
    .text-center-bottom{
    font-size: 0.5rem !important;
    // margin-bottom: -10% !important;
  }
}
}
.quiz-bottom-section {
  .image-with-text {
    position: relative; // Omogućava pozicioniranje sadržaja unutar ovog diva
    width: 114%; // Širina slike ili kontejnera
    @media (max-width: 1870px) and (min-width: 1821px) {
      width: 111%;
    }
    
    @media (max-width: 1820px) and (min-width: 1771px) {
      width: 108%;
    }
    
    @media (max-width: 1770px) and (min-width: 1721px) {
      width: 105%;
    }
    
    @media (max-width: 1720px) and (min-width: 1671px) {
      width: 102%;
    }
    
    @media (max-width: 1670px) and (min-width: 1621px) {
      width: 96%;
    }
    
    @media (max-width: 1620px) and (min-width: 1571px) {
      width: 91%;
    }
    
    @media (max-width: 1570px) and (min-width: 1521px) {
      width: 92%;
    }
    
    @media (max-width: 1520px) and (min-width: 1471px) {
      width: 92%;
    }
    
    @media (max-width: 1470px) and (min-width: 1421px) {
      width: 92%;
    }
    
    @media (max-width: 1420px) and (min-width: 1400px) {
      width: 92%;
    }
    @media (max-width: 1399px) and (min-width: 1350px) {
      width: 90%;
    }
    
    @media (max-width: 1349px) and (min-width: 1300px) {
      width: 90%;
    }
    
    @media (max-width: 1299px) and (min-width: 1250px) {
      width: 86%;
    }
    
    @media (max-width: 1249px) and (min-width: 1200px) {
      width: 86%;
    }
    @media (max-width: 1199px) and (min-width: 1150px) {
      width:96%;
    }
    
    @media (max-width: 1149px) and (min-width: 1100px) {
      width:92%;
    }
    
    @media (max-width: 1099px) and (min-width: 1050px) {
      width:84%;
    }
    
    @media (max-width: 1049px) and (min-width: 992px) {
      width:84%;
    }        
    @media (max-width: 991px) and (min-width: 941px){
      width:105%;
    }
  
    @media (max-width: 940px) and (min-width: 890px){  
      width:105%;
    }
  
    @media (max-width: 889px) and (min-width: 839px){  
      width:101%;
    }
    
    @media (max-width: 838px) and (min-width: 768px){
      width:101%;
    }
    @media (max-width: 767px) and (min-width: 717px){
      width:116%;
    } 
    
	  @media (max-width: 716px) and (min-width: 666px){
        width:116%;
      } 	
    
	  @media (max-width: 665px) and (min-width: 615px){
        width:116%;
      } 

	  @media (max-width: 665px) and (min-width: 615px){
        width:116%;
      } 	
    
	  @media (max-width: 614px) and (min-width: 576px){
        width:116%;
      }

    @media (max-width: 575px) {
      width:105%;
    } 
      
    
    // @media (max-width: 768px) {
    //   width: 101%; // Širina slike ili kontejnera
    // }
    .text-overlay {
      position: absolute; // Pozicionira tekst preko slike
      top: 36%; // Prilagodite poziciju teksta prema potrebi
      left: 2%; // Pozicija levo
      color: white; // Bele boje teksta
      text-align: center !important; // Tekst levo poravnat
      width: 68%;
      @media (max-width: 1750px) and (min-width: 1561px) {
        left: 4%;
        width: 64%;
      }
      @media (max-width: 1560px) and (min-width: 1401px){
        left: 4%;
        width: 64%;
      }
      @media (max-width: 1400px) and (min-width: 1201px){
        left: 4%;
        width: 64%;
      }
      @media (max-width: 1200px) and (min-width: 992px){
        left: 4%;
        width: 64%;
      }
      @media (max-width: 991px) and (min-width: 769px){
        left: 3%;
        width: 66%;
      }

      @media (max-width: 767px) and (min-width: 717px){
        left: 2%;
        width: 66%;
      } 
      
      @media (max-width: 716px) and (min-width: 666px){
        left: 2%;
        width: 66%;
        } 	
      
      @media (max-width: 665px) and (min-width: 615px){
        left: 2%;
        width: 66%;
        } 
  
      @media (max-width: 665px) and (min-width: 615px){
        left: 2%;
        width: 66%;
        } 	
      
      @media (max-width: 614px) and (min-width: 576px){
        left: 2%;
        width: 66%;
        }
      @media (max-width: 575px) {
        left: 4%;
        width: 94%;
      }     
      
    }
    .step-container {
      position: absolute; // Pozicionira tekst preko slike
      top: 72%; // Prilagodite poziciju teksta prema potrebi
      left: 88%; // Postavite početnu poziciju za ceo sadržaj
      display: flex; // Postavlja sadržaj u red
      align-items: center; // Vertikalno centriranje
      @media (max-width: 768px) {
        position: absolute;
        top: 92%;
        left: 34%;
        width: 100%;
        // display:none !important;
      }

      // .quiz-button {
      //   background-color: #DC6819; /* Boja pozadine */
      //   color: white; /* Boja teksta */
      //   font-weight: bold; /* Podebljan tekst */
      //   text-transform: uppercase; /* Velika slova */
      //   padding: 10px 10px 0px 10px;
      //   border: none; /* Bez ivica */
      //   border-radius: 5px; /* Zaobljeni uglovi */
      //   cursor: pointer; /* Promena kursora */
      //   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Senka za estetiku */
      //   transition: background-color 0.3s ease, transform 0.2s ease; /* Efekti prelaza */
      //   @media (max-width: 991px) and (min-width: 769px){
      //     background-color: #DC6819; /* Boja pozadine */
      //     color: white; /* Boja teksta */
      //     font-weight: bold; /* Podebljan tekst */
      //     text-transform: uppercase; /* Velika slova */
      //     padding: 10px 10px 0px 10px;
      //     border: none; /* Bez ivica */
      //     border-radius: 5px; /* Zaobljeni uglovi */
      //     cursor: pointer; /* Promena kursora */
      //     box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Senka za estetiku */
      //     transition: background-color 0.3s ease, transform 0.2s ease; /* Efekti prelaza */
      //   }
      //   @media (max-width: 768px) {
      //     padding: 5px 5px 0px 5px;
      //     background-color: transparent !important;
      //     box-shadow: none !important;
      //   }
    
      //   &:hover {
      //     background-color: darkorange; /* Tamnija nijansa na hover */
      //     transform: scale(1.05); /* Blago povećanje */
      //     color:#00a999;
      //     @media (max-width: 992px) {
      //       background-color: transparent !important;
      //     }
      //   }
    
      //   &:active {
      //     transform: scale(0.95); /* Blago smanjenje na klik */
      //   }
      //   .pokreni-text {
      //     font-size: 2.4rem; /* Manji font za "POKRENI" */
      //     display: block; /* Svaki element zauzima ceo red */
      //     margin: 0; /* Uklanja bilo kakav razmak */
      //     padding: 0; /* Uklanja padding */
      //     line-height: 1;
      //     font-family: "DINCondensedBold";
      //     @media (max-width: 1750px) and (min-width: 1561px) {
      //       font-size: 2rem;
      //     }
      //     @media (max-width: 1560px) and (min-width: 1401px){
      //       font-size: 2rem;
      //     }
      //     @media (max-width: 1400px) and (min-width: 1201px){
      //       font-size: 2rem;
      //     }
      //     @media (max-width: 1200px) and (min-width: 992px){
      //       font-size: 1.6rem;
      //     }
      //     @media (max-width: 991px) and (min-width: 769px){
      //       font-size: 1.2rem;
      //     }
      //     @media (max-width: 768px) {
      //       font-size: 1.8rem;

      //     }
      //   }
      
      //   .kviz-text {
      //     font-size: 4rem; /* Veći font za "KVIZ" */
      //     display: block; /* Svaki element zauzima ceo red */
      //     margin: 0; /* Uklanja bilo kakav razmak */
      //     padding: 0; /* Uklanja padding */
      //     line-height: 1;
      //     font-family: "DINCondensedBold";
      //     @media (max-width: 1750px) and (min-width: 1561px) {
      //       font-size: 3.2rem;
      //     }
      //     @media (max-width: 1560px) and (min-width: 1401px){
      //       font-size: 2.8rem;
      //     }
      //     @media (max-width: 1400px) and (min-width: 1201px){
      //       font-size: 2rem;
      //     }
      //     @media (max-width: 1200px) and (min-width: 992px){
      //       font-size: 1.8rem;
      //     }
      //     @media (max-width: 991px) {
      //       font-size: 1.2rem;
      //     }
      //   }
      // }
    }
  }
  
  .image-with-text img {
    width: 100%; // Slika će zauzeti punu širinu
    height: auto; // Automatski prilagođavanje visine
  }
  
  .font-uppercase2 {
    // text-transform: uppercase; // Tekst velikim slovima
    font-size: 0.9rem; // Veličina fonta
    margin: 0; // Uklanja podrazumevani razmak
    @media (max-width: 1870px) and (min-width: 1821px) {
      font-size: 0.9rem;
    }
    
    @media (max-width: 1820px) and (min-width: 1771px) {
      font-size: 0.9rem;
    }
    
    @media (max-width: 1770px) and (min-width: 1721px) {
      font-size: 0.9rem;
    }
    
    @media (max-width: 1720px) and (min-width: 1671px) {
      font-size: 0.9rem;
    }
    
    @media (max-width: 1670px) and (min-width: 1621px) {
      font-size: 0.9rem;
    }
    
    @media (max-width: 1620px) and (min-width: 1571px) {
      font-size: 0.8rem;
    }
    
    @media (max-width: 1570px) and (min-width: 1521px) {
      font-size: 0.8rem;
    }
    
    @media (max-width: 1520px) and (min-width: 1471px) {
      font-size: 0.8rem;
    }
    
    @media (max-width: 1470px) and (min-width: 1421px) {
      font-size: 0.8rem;
    }
    
    @media (max-width: 1420px) and (min-width: 1400px) {
      font-size: 0.8rem;
    }
    @media (max-width: 1399px) and (min-width: 1350px) {
      font-size: 0.7rem;
    }
    
    @media (max-width: 1349px) and (min-width: 1300px) {
      font-size: 0.7rem;
    }
    
    @media (max-width: 1299px) and (min-width: 1250px) {
      font-size: 0.6rem;
    }
    
    @media (max-width: 1249px) and (min-width: 1200px) {
      font-size: 0.6rem;
    }

    @media (max-width: 1199px) and (min-width: 1150px) {
      font-size: 0.55rem;
    }
    
    @media (max-width: 1149px) and (min-width: 1100px) {
      font-size: 0.55rem;
    }
    
    @media (max-width: 1099px) and (min-width: 1050px) {
      font-size: 0.55rem;
    }
    
    @media (max-width: 1049px) and (min-width: 992px) {
      font-size: 0.55rem;
    }        

    @media (max-width: 991px) and (min-width: 768px){
      font-size: 0.4rem;
    }
    @media (max-width: 767px) and (min-width: 576px) {
      font-size: 0.4rem;
      line-height: 1 !important;
    }
    // @media (max-width: 767px) and (min-width: 576px) {
    //   font-size: 0.8rem;
    //   line-height: 1.2 !important;
    // }
    @media (max-width: 575px) and (min-width: 391px) {
      font-size: 0.6rem;
      line-height: 1.2 !important;
    }
    @media (max-width: 390px) {
      font-size: 0.55rem;
      line-height: 1.2 !important;
    }
}
  
  .ultra-text2 {
    font-weight: bold; // Podebljan tekst
    color: #f0d000; // Žuta boja za naglašavanje
  }
}

//form stil
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #006666, #00cccc);
}

.form {
  background-color: #008080;
  padding: 20px 30px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  width: 400px;
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 10px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #00b3b3;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #009999;
}
.error {
  color: rgb(243, 216, 16);
  font-size: 0.9rem;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: left;
}
.privacy a {
  color: #ffd700; /* Zlatna boja za link */
  text-decoration: none; /* Uklanja podvlačenje linka */
  font-weight: bold;
}

.privacy a:hover {
  text-decoration: underline; /* Dodaje podvlačenje na hover */
}

.success {
  color: rgb(250, 212, 63); /* Zelena boja */
  font-size: 1.4rem;
  margin-top: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}
// .required {
//   color: rgb(237, 220, 37);
//   margin-left: 5px;
// }

// .error {
//   color: red;
//   font-size: 0.9rem;
//   margin-top: 5px;
//   margin-bottom: 10px;
//   text-align: left;
// }

.quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #006666, #00cccc);
  color: white;
  font-family: Arial, sans-serif;
}

.kivz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 600px;
  background-color: #008080;
  padding: 20px 30px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.kivz .logo {
  width: 120px;
  margin-bottom: 20px;
}

.kivz h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.kivz div {
  font-size: 16px;
  margin-bottom: 15px;
  text-align: center;
}

.odgovori {
  display: grid;
  gap: 15px;
  justify-content: center;
  width: 100%;
  max-width: 600px;
}

@media (min-width: 768px) {
  .odgovori {
    grid-template-columns: repeat(2, 1fr); /* Dva odgovora po redu na većim ekranima */
  }

  .desktop-image {
    display: block; /* Prikazujemo desktop sliku na većim ekranima */
  }

  .mobile-image {
    display: none; /* Sakrivamo mobilnu sliku na većim ekranima */
  }
}

@media (max-width: 767px) {
  .odgovori {
    grid-template-columns: 1fr; /* Jedan odgovor po redu na manjim ekranima */
  }

  .desktop-image {
    display: none; /* Sakrivamo desktop sliku na manjim ekranima */
  }

  .mobile-image {
    display: block; /* Prikazujemo mobilnu sliku na manjim ekranima */
  }
}

.odgovori button {
  background-color: #00b3b3;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.odgovori button:hover {
  background-color: #006666;
}

.odgovori button:active {
  background-color: #006666;
}

.desktop-image,
.mobile-image {
  width: 100%; /* Prilagodljiva širina slike */
  max-width: 600px; /* Maksimalna širina slike */
  height: auto; /* Održavanje proporcija slike */
  margin: 0 auto 20px; /* Centriranje i margina ispod slike */
}


.zahvalnica {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(135deg, #006d6d, #009999);
  color: white;
  padding: 5%;
  border-radius: 25px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  max-width: 90%;
  margin: 5% auto;
  border: 2px solid #00cccc;
}

.zahvalnica h1 {
  font-size: 3rem; /* Postavljen font na 3rem */
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
  word-wrap: break-word;
  font-family: "DINCondensedBold";
}

.zahvalnica button {
  background-color: #00cccc;
  color: white;
  border: none;
  border-radius: 12px;
  font-family: "AkiraExpandedDemo";
  padding: 1.2rem 2.2rem; /* Proporcionalno povećano */
  font-size: 1.6rem; /* Proporcionalno prilagođeno */
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  width:100%; /* Povećano za bolje prilagođavanje */
  max-width: 300px; /* Maksimalna širina dugmeta */
  text-align: center;
}

.zahvalnica button:hover {
  background-color: #008f8f;
  transform: scale(1.05);
}

.zahvalnica button:active {
  background-color: #007373;
  transform: scale(0.95);
}

/* Stilovi za mobilne uređaje */
@media (max-width: 768px) {
  .zahvalnica {
    padding: 8%;
  }

  .zahvalnica h1 {
    font-size: 2.5rem; /* Smanjeno za manje ekrane */
  }

  .zahvalnica button {
    font-size: 1.25rem;
    padding: 0.8rem 1.5rem;
  }
}


// .quiz-bottom-section {
//   position: fixed; /* Zakucano na dno ekrana */
//   bottom: 0;
//   left: 0;
//   width: 100%; /* Prostire se preko cele širine */
//   height: 80px; /* Visina sekcije */
//   z-index: 1000; /* Postavlja komponentu iznad ostalih elemenata */
//   background-color: #001f3f; /* Rezervna boja pozadine */

//   .image-with-text {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     align-items: center; /* Vertikalno centriranje slike */

//     img {
//       width: 100%; /* Slika zauzima ceo prostor */
//       height: auto; /* Automatska visina */
//     }
//   }

//   .quiz-button {
//     background-color: #dc6819; /* Boja pozadine dugmeta */
//     color: white;
//     font-weight: bold;
//     text-transform: uppercase;
//     padding: 10px 20px;
//     border: none;
//     border-radius: 5px;
//     cursor: pointer;
//     box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
//     transition: background-color 0.3s ease, transform 0.2s ease;

//     &:hover {
//       background-color: darkorange;
//       transform: scale(1.05);
//     }

//     &:active {
//       transform: scale(0.95);
//     }

//     .pokreni-text {
//       font-size: 1.2rem;
//       display: block;
//       font-family: "DINCondensedBold";
//       margin: 0;
//       line-height: 1;
//     }

//     .kviz-text {
//       font-size: 1.8rem;
//       display: block;
//       font-family: "DINCondensedBold";
//       margin: 0;
//       line-height: 1;
//     }
//   }

//   @media (max-width: 768px) {
//     height: 60px;

//     .quiz-button {
//       padding: 8px 16px;

//       .pokreni-text {
//         font-size: 1rem;
//       }

//       .kviz-text {
//         font-size: 1.5rem;
//       }
//     }
//   }
// }

.footer-bar {
  height: 14%; /* Visina footer-a */
  background-color: transparent; /* Transparentna pozadina */
  padding: 0;
  // @media (max-width: 1870px) and (min-width: 1821px) {
  //   width: 111%;
  // }
  
  @media (max-width: 1820px) and (min-width: 1771px) {
    height: 13%;
  }
  
  @media (max-width: 1770px) and (min-width: 1721px) {
    height: 13%;
  }
  
  @media (max-width: 1720px) and (min-width: 1671px) {
    height: 13%;
  }
  
  @media (max-width: 1670px) and (min-width: 1621px) {
    height: 11%;
  }
  
  @media (max-width: 1620px) and (min-width: 1571px) {
    height: 11%;
  }
  
  @media (max-width: 1570px) and (min-width: 1521px) {
    height: 11%;
  }
  
  @media (max-width: 1520px) and (min-width: 1471px) {
    height: 11%;
  }
  
  @media (max-width: 1470px) and (min-width: 1421px) {
    height: 11%;
  }
  
  @media (max-width: 1420px) and (min-width: 1400px) {
    height: 11%;
  }
  @media (max-width: 1399px) and (min-width: 1350px) {
    height: 9%;
  }
  
  @media (max-width: 1349px) and (min-width: 1300px) {
    height: 9%;
  }
  
  @media (max-width: 1299px) and (min-width: 1250px) {
    height: 9%;
  }
  
  @media (max-width: 1249px) and (min-width: 1200px) {
    height: 9%;
  }
  @media (max-width: 1199px) and (min-width: 1150px) {
    height: 8%;
  }
  
  @media (max-width: 1149px) and (min-width: 1100px) {
    height: 8%;
  }
  
  @media (max-width: 1099px) and (min-width: 1050px) {
    height: 7%;
  }
  
  @media (max-width: 1049px) and (min-width: 992px) {
    height: 7%;
  }        
  @media (max-width: 991px) and (min-width: 941px){
    height: 7%;
  }

  @media (max-width: 940px) and (min-width: 890px){  
    height: 7%;
  }

  @media (max-width: 889px) and (min-width: 839px){  
    height: 7%;
  }
  
  @media (max-width: 838px) and (min-width: 768px){
    height: 6%;
  }

  @media (max-width: 767px) and (min-width: 576px) {
    height: 5%; /* Visina footer-a */
  }
  @media (max-width: 575px) {
    height: 13%;
  }
  @media (max-width: 575px) {
    .fixed-bottom{
      right: -8%;
    }
  }

  .footer-image {
    width: 120%; /* Širina slike */
    height: auto; /* Automatska visina kako bi zadržala proporcije */
    margin-left: -10%;
    @media (max-width: 1820px) and (min-width: 1771px) {
      width: 114%;
      height: auto;
      margin-left: -7%;
    }
    
    @media (max-width: 1770px) and (min-width: 1721px) {
      width: 114%;
      height: auto;
      margin-left: -7%;
    }
    
    @media (max-width: 1720px) and (min-width: 1671px) {
      width: 114%;
      height: auto;
      margin-left: -7%;
    }
    
    @media (max-width: 1670px) and (min-width: 1621px) {
      width: 100%;
      height: auto;
      margin-left: 0%;
    }
    
    @media (max-width: 1620px) and (min-width: 1571px) {
      width: 100%;
      height: auto;
      margin-left: 0%;
    }
    
    @media (max-width: 1570px) and (min-width: 1521px) {
      width: 100%;
      height: auto;
      margin-left: 0%;
    }
    
    @media (max-width: 1520px) and (min-width: 1471px) {
      width: 100%;
      height: auto;
      margin-left: 0%;
    }
    
    @media (max-width: 1470px) and (min-width: 1421px) {
      width: 96%;
      height: auto;
      margin-left: 2%;
    }
    
    @media (max-width: 1420px) and (min-width: 1400px) {
      width: 96%;
      height: auto;
      margin-left: 2%;
    }
    @media (max-width: 1399px) and (min-width: 1350px) {
      width: 96%;
      height: auto;
      margin-left: 2%;
    }
    
    @media (max-width: 1349px) and (min-width: 1300px) {
      width: 96%;
      height: auto;
      margin-left: 2%;
    }
    
    @media (max-width: 1299px) and (min-width: 1250px) {
      width: 96%;
      height: auto;
      margin-left: 2%;
    }
    
    @media (max-width: 1249px) and (min-width: 1200px) {
      width: 96%;
      height: auto;
      margin-left: 2%;
    }
    @media (max-width: 1199px) and (min-width: 1150px) {
      width: 104%;
      height: auto;
      margin-left: -2%;
    }
    
    @media (max-width: 1149px) and (min-width: 1100px) {
      width: 90%;
      height: auto;
      margin-left: 5%;
    }
    
    @media (max-width: 1099px) and (min-width: 1050px) {
      width: 90%;
      height: auto;
      margin-left: 5%;
    }
    
    @media (max-width: 1049px) and (min-width: 992px) {
      width: 90%;
      height: auto;
      margin-left: 5%;
    }   
    // @media (max-width: 991px) and (min-width: 576px){
    //   width: 108%; /* Širina slike */
    //   height: auto; /* Automatska visina kako bi zadržala proporcije */
    //   margin-left: -4%;
    // }
    
    @media (max-width: 991px) and (min-width: 768px){
      width: 108%; /* Širina slike */
      height: auto; /* Automatska visina kako bi zadržala proporcije */
      margin-left: -4%;
    }
    @media (max-width: 767px) and (min-width: 576px) {
      width: 121%;
      height: auto;
      margin-left: -11%;
    }
    @media (max-width: 575px) {
      position: absolute;
      top: 47%;
      width: 100%;
    }
  }

  .quiz-button {
    position: absolute;
    z-index: 2; /* Dugme iznad slike */
    top: 50%;
    right: 12%; /* Udaljenost od desnog ruba */
    transform: translateY(-50%); 
    background-color: #DC6819; /* Boja pozadine */
    color: white; /* Boja teksta */
    font-weight: bold; /* Podebljan tekst */
    text-transform: uppercase; /* Velika slova */
    padding: 10px 10px 0px 10px;
    border: none; /* Bez ivica */
    border-radius: 5px; /* Zaobljeni uglovi */
    cursor: pointer; /* Promena kursora */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Senka za estetiku */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Efekti prelaza */

      @media (min-width: 2401px)  {
        right: 20%;
      }

      @media (min-width: 2301px) and (max-width: 2400px) {
        right: 18%;
      }

      @media (min-width: 2200px) and (max-width: 2300px) {
        right: 18%;
      }
      @media (min-width: 2100px) and (max-width: 2199px) {
        right: 16%;
      }
    
      @media (max-width: 1420px) and (min-width: 1400px) {
        right: 9%;
      }

      @media (max-width: 1399px) and (min-width: 1350px) {
        right: 12%;
      }
      
      @media (max-width: 1349px) and (min-width: 1300px) {
        right: 12%;
      }
      
      @media (max-width: 1299px) and (min-width: 1250px) {
        right: 8%;
      }
      
      @media (max-width: 1249px) and (min-width: 1200px) {
        right: 8%;
      }
      @media (max-width: 1199px) and (min-width: 1150px) {
        right: 10%;
      }
      
      @media (max-width: 1149px) and (min-width: 1100px) {
        right: 10%;
      }
      
      @media (max-width: 1099px) and (min-width: 1050px) {
        right: 10%;
      }
      
      @media (max-width: 1049px) and (min-width: 992px) {
        right: 10%;
      }   

      @media (max-width: 991px) and (min-width: 941px){
          top: 50%;
          right: 11%;
          background-color: #DC6819; /* Boja pozadine */
          color: white; /* Boja teksta */
          font-weight: bold; /* Podebljan tekst */
          text-transform: uppercase; /* Velika slova */
          padding: 10px 10px 0px 10px;
          border: none; /* Bez ivica */
          border-radius: 5px; /* Zaobljeni uglovi */
          cursor: pointer; /* Promena kursora */
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Senka za estetiku */
          transition: background-color 0.3s ease, transform 0.2s ease; /* Efekti prelaza */
        }
      
        @media (max-width: 940px) and (min-width: 890px){  
          right: 10%;
        }
      
        @media (max-width: 889px) and (min-width: 839px){  
          right: 5%;
        }
        
        @media (max-width: 838px) and (min-width: 768px){
          right: 5%;
        }
        @media (max-width: 767px) and (min-width: 717px){
          right: 4%;
        } 
        
        @media (max-width: 716px) and (min-width: 666px){
          right: 8%;
          } 	
        
        @media (max-width: 665px) and (min-width: 615px){
          right: 4%;
          } 
    	
        
        @media (max-width: 614px) and (min-width: 576px){
          right: 2%;
          }
        // @media (max-width: 767px) and (min-width: 576px){
        //   top: 51%;
        //   right: 2%;
        // }
        @media (max-width: 575px) {
          padding: 20px 45px 18px 45px;
          position: absolute;
          top: 80%;
          width: 100%;
          right: 0%;
        }

    &:hover {
      background-color: rgb(246, 183, 107); /* Tamnija nijansa na hover */
      // transform: scale(1.035); /* Blago povećanje */
      // color:#00a999;
      @media (max-width: 992px) {
        background-color: transparent !important;
      }
    }

    // &:active {
    //   transform: scale(0.95); /* Blago smanjenje na klik */
    // }
    .pokreni-text {
      font-size: 2.4rem; /* Manji font za "POKRENI" */
      display: block; /* Svaki element zauzima ceo red */
      margin: 0; /* Uklanja bilo kakav razmak */
      padding: 0; /* Uklanja padding */
      line-height: 1;
      font-family: "DINCondensedBold";
      @media (max-width: 1750px) and (min-width: 1561px) {
        font-size: 2rem;
      }
      @media (max-width: 1560px) and (min-width: 1401px){
        font-size: 2rem;
      }
      @media (max-width: 1400px) and (min-width: 1201px){
        font-size: 2rem;
      }
      @media (max-width: 1200px) and (min-width: 992px){
        font-size: 1.6rem;
      }
      @media (max-width: 991px) and (min-width: 769px){
        font-size: 1.2rem;
      }
      @media (max-width: 768px) and (min-width: 576px){
        font-size: 1rem;
        line-height: 1.2rem;

      }
      @media (max-width: 575px) {
        font-size: 1.8rem;

      }
    }
  
    .kviz-text {
      font-size: 4rem; /* Veći font za "KVIZ" */
      display: block; /* Svaki element zauzima ceo red */
      margin: 0; /* Uklanja bilo kakav razmak */
      padding: 0; /* Uklanja padding */
      line-height: 1;
      font-family: "DINCondensedBold";
      @media (max-width: 1750px) and (min-width: 1561px) {
        font-size: 3.2rem;
      }
      @media (max-width: 1560px) and (min-width: 1401px){
        font-size: 2.8rem;
      }
      @media (max-width: 1400px) and (min-width: 1201px){
        font-size: 2rem;
      }
      @media (max-width: 1200px) and (min-width: 992px){
        font-size: 1.8rem;
      }
      @media (max-width: 991px) {
        font-size: 1rem;
      }
    }
  }
}

.end-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  // background: linear-gradient(to bottom, #006666, #00cccc);
  // border-radius: 15px;
  color: white;
  font-family: "DINCondensedBold";
}

.end-game-title {
  font-size: 3rem;
  // margin-bottom: 20px;
  font-weight: bold;
}

.follow-us {
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
}

.follow-us-box {
  background: linear-gradient(to bottom, #006666, #00cccc);
  border-radius: 15px;
  padding: 10px 10px 0px 10px;
  margin-bottom: 20px;
}

.follow-us-box p {
  font-size: 2.2rem;
  font-weight: bold;
  margin: 0;
}

.social-icons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.social-link {
  text-decoration: none;
}

.social-icon {
  width: 80px;
  height: 80px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
  opacity: 0.8;
}




