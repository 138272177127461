@font-face {
    font-family: 'GothamBold';
    src: url('./fonts/GothamBold.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'GothamBlack';
    src: url('./fonts/Gotham-Black.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'GothamBoldPravi';
    src: url('./fonts/Gotham-Bold-pravi.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'AkiraExpandedDemo';
    src: url('./fonts/AkiraExpandedDemo.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'DINCondensedBold';
    src: url('./fonts/DINCondensedBold.ttf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'DINPro-Medium';
    src: url('./fonts/DINPro-Medium.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }