.date_picker {
    display: flex;
    justify-content: center; /* Centriranje DatePicker-a */
    align-items: center;
    margin: 10px 0; /* Razmak između DatePicker-a i ostalih elemenata */
  }
  
  .date_picker input {
    width: 150px; /* Širina unosa */
    height: 40px; /* Visina unosa */
    border: 2px solid #0d8d87; /* Okvir u skladu sa bojama aplikacije */
    border-radius: 5px; /* Zaobljeni uglovi */
    font-size: 1.8rem; /* Veličina fonta */
    font-weight: bold; /* Podebljan tekst */
    text-align: center; /* Centriranje teksta */
    color: #0d8d87; /* Boja teksta */
    background-color: white; /* Pozadina */
    cursor: pointer; /* Indikator za klik */
    transition: all 0.3s ease; /* Efekat prelaza */
    @media (max-width: 991px) and (min-width: 941px){
      width: 135px; /* Širina unosa */
      height: 32px; /* Visina unosa */
    }
  
    @media (max-width: 940px) and (min-width: 890px){  
      width: 135px; /* Širina unosa */
      height: 32px; /* Visina unosa */
    }
  
    @media (max-width: 889px) and (min-width: 839px){  
      width: 135px; /* Širina unosa */
      height: 32px; /* Visina unosa */
    }
    
    @media (max-width: 838px) and (min-width: 768px){
      width: 135px; /* Širina unosa */
      height: 32px; /* Visina unosa */
    } 
  }
  
  .date_picker input:focus {
    outline: none; /* Uklanja standardni outline */
    border-color: #096b63; /* Tamnija boja pri fokusu */
    box-shadow: 0 0 5px #096b63; /* Svetlosni efekat */
  }
  
  .date_picker input:hover {
    border-color: #096b63; /* Tamnija boja na hover */
  }
  
  .date_picker th,
  .date_picker td {
    width: 38px !important;
    height: 38px !important;
    text-align: center; /* Centriranje datuma u kalendaru */
    vertical-align: middle; /* Vertikalno centriranje */
  }
  .SingleDatePicker_picker {
    z-index: 9999; /* Osigurava da je kalendar iznad svih drugih elemenata */
 /*   top: -250px !important; /* Podiže kalendar iznad trenutne pozicije */
    left: 50% !important; /* Centriranje horizontalno */
    transform: translateX(-50%); /* Ispravlja horizontalno centriranje */
  }

  @media (max-width: 768px) {
    .date_picker input {
        width: 66px !important; /* Tačna širina */
        height: 26px !important; /* Tačna visina */
        font-size: 1rem !important; /* Tačan font */
        padding: 0 !important; /* Bez unutrašnje margine */
        margin: 0 !important; /* Bez spoljne margine */
        border: 1px solid #0d8d87 !important; /* Okvir */
        border-radius: 5px !important; /* Zaobljeni uglovi */
        text-align: center !important; /* Centriran tekst */
        background-color: transparent !important; /* Transparentna pozadina */
        box-sizing: border-box; /* Ukupna veličina sa borderom */
    }

    .SingleDatePickerInput {
        display: flex !important; /* Fleksibilni prikaz */
        align-items: center !important; /* Vertikalno centriranje */
        justify-content: center !important; /* Horizontalno centriranje */
        padding: 0 !important; /* Bez unutrašnjih margina */
        height: 26px !important; /* Usaglašena visina */
        background-color: transparent !important; /* Transparentna pozadina */
    }

    .SingleDatePickerInput__withBorder {
        border: none !important; /* Bez dodatnog okvira */
        padding: 0 !important; /* Bez dodatnog prostora */
        background-color: transparent !important; /* Transparentna pozadina */
    }

    .DateInput {
        max-width: 66px !important; /* Maksimalna širina */
        height: 26px !important; /* Maksimalna visina */
        margin: 0 !important; /* Bez margine */
        background-color: transparent !important; /* Transparentna pozadina */
    }

    .DateInput_input {
        text-align: center !important; /* Centriran tekst */
        padding: 0 !important; /* Bez unutrašnjih margina */
        border: none !important; /* Bez okvira */
        font-size: 1rem !important; /* Veličina fonta */
        line-height: 1 !important; /* Linija da se uklopi */
        background-color: transparent !important; /* Transparentna pozadina */
        height: 26px !important; /* Ograničenje visine unosa */
    }

    .SingleDatePicker_picker {
        z-index: 9999 !important; /* Zadrži visoki nivo */
        background-color: transparent !important; /* Transparentna pozadina kalendara */
    }
}